import React from 'react'
import Helmet from 'react-helmet'
import Layout from '../components/layout'

const Mv = props => (
  <Layout>
    <Helmet>
      <title>Contact Us</title>
      <meta name="description" content="Contact Us" />
    </Helmet>

    <div id="main" className="wrapper style1">
      <div className="container">
        <header className="major">
          <h2>Mission and Vision </h2>
          
        </header>
        <div className="row gtr-150">
          <div className="col-12 col-12-medium">
            <section id="content">
              {/* <a href="#" className="image fit">
                <img src="https://www.sgpfoods.com/img/strawberry.jpg" alt="" />
              </a> */}
              <h3>Our Mission &amp; Core-Values</h3>
              <p>
              
              SGP Foods aims to help Singapore meet her climate change goals through the development of proprietary technologies and processes. Through our carbon crediting, verticulture, and energy efficiency solutions, we put Singapore in place to be a leader in the fight against climate change. We combat climate change by engaging the 3 ‘P’s – the public sector, the private sector, and the people. This is a multi-pronged approach involving technology, research, and education.</p>
              
              <h3>Our Technologies</h3>
              <p>
              SGP Foods takes pride in our proprietary and patented technologies. The fact that we invent and create our own technologies speaks volumes about the level of customisation and optimisation we are able to provide our business with. We also take a highly scientific approach towards all our processes. These ensure that we are constantly at the forefront of innovation.</p>
              
               
             
            </section>
          </div>
        </div>
      </div>
    </div>
  </Layout>
)

export default Mv
